import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import { addPositionToDb, getPositions } from "./base";

import { nanoid } from "nanoid";

import Chess from "chess.js";

import {
  useDisclosure,
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalContent,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Textarea,
  Switch,
  ModalFooter,
  Button,
  Center,
  useToast,
  Alert,
  AlertTitle,
  CloseButton,
  Text,
} from "@chakra-ui/react";

import { AiOutlinePlusCircle } from "react-icons/ai";

import { CUIAutoComplete } from "chakra-ui-autocomplete";

import useStateRef from "react-usestateref";

import Helper from "./functions/Helper";
import { Timestamp } from "@firebase/firestore";

const AddPosition = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    addSamplePositionsRef() {
      addSamplePositions();
    },
  }));

  /////////// Tags /////////////////////
  const [allTags, setAllTags, allTagsRef] = useStateRef(["all"]);
  const [pickerItems, setPickerItems] = useState(Helper.defaultTags);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
    }
  };

  ///////// end tags //////////////////

  const resetFields = () => {
    onCloseAddPosition();
    setPgnOrientationField(true);
    setFenField("");
    setBestMovesField("");
    setPgnField("");
    setSelectedItems([]);
    setTitleField("");
    setNotesField("");
    setLastMoveField("");
  };

  const resetTags = (newBoards) => {
    let tags = ["all"];

    for (var q of newBoards) {
      for (var q2 of q.tags) {
        if (!tags.includes(q2)) tags.push(q2);
      }
    }
    props.setAllTags(tags);
  };

  const buildPickerItems = () => {
    let newPickerItems = [...pickerItems];
    let newPickerItems2 = [];

    for (var r of newPickerItems) {
      newPickerItems2.push(r.value);
    }

    for (var boards of props.boards) {
      for (var q2 of boards.tags) {
        if (!newPickerItems2.includes(q2))
          newPickerItems.push({ value: q2, label: q2 });
      }
    }

    newPickerItems = newPickerItems.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.value === thing.value && t.label === thing.label
        )
    );

    setPickerItems(newPickerItems);
  };

  const addPositionRef = useRef();

  const toast = useToast();
  const [addFenField, setFenField] = useState("");
  const [notesField, setNotesField] = useState("");
  const [pgnField, setPgnField] = useState("");
  const [pgnOrientation, setPgnOrientationField, pgnOrientationRef] =
    useStateRef(true);
  const [titleField, setTitleField] = useState("");

  const [bestMovesField, setBestMovesField] = useState("");
  const [lastMoveField, setLastMoveField] = useState("");

  const [hideSampleAlert, setHideSampleAlert, hideSampleAlertRef] =
    useStateRef(false);

  const {
    isOpen: isOpenAddPosition,
    onOpen: onOpenAddPosition,
    onClose: onCloseAddPosition,
  } = useDisclosure();

  useEffect(() => {
    if (props.userLoadedRef.current) {
      toast.closeAll();
      // console.log(props.urlUid);
      // setupPositions(props.urlUid);

      if (!props.user) {
        // clearPositions();
        window.location.reload(false);
      }
    }
  }, [props.user, props.userLoadedRef]);

  useEffect(() => {
    setupPositions(props.urlUid);
    // let p = window.location.pathname;
    // p = p.split("/").pop();
    // if (p === "") {
    //   setTimeout(() => {
    //     if (!props.userLoadedRef.current) {
    //       toast({
    //         // ref: sampleGamesToast,
    //         title: "End of game.",
    //         description: "Add sample positions",
    //         status: "success",
    //         duration: 10000000,
    //         position: "bottom",
    //         isClosable: true,
    //         render: () => (
    //           <Box display={hideSampleAlertRef.current ? "none" : "block"}>
    //             <Alert status="info" width="400px">
    //               <AlertTitle> Welcome, add sample positions? </AlertTitle>
    //               <Button
    //                 onClick={() => {
    //                   addSamplePositions();
    //                   setHideSampleAlert(true);
    //                 }}
    //               >
    //                 Add
    //               </Button>
    //               <CloseButton
    //                 onClick={() => {
    //                   setHideSampleAlert(true);
    //                 }}
    //                 position="absolute"
    //                 right="8px"
    //                 top="8px"
    //               />
    //             </Alert>
    //           </Box>
    //         ),
    //       });
    //     }
    //   }, 2000);
    // }
  }, []);

  const addSamplePositions = () => {
    addPosition(
      {
        fen: "8/ppp2kpp/2n5/2b5/2P1q3/6K1/PP4PP/R4BNR b - - 3 18",
        movesString:
          "Nd4 Nf3 Bd6+ Kf2 Qc2+ Nd2 Qxd2+ Be2 Qxe2+ Kg1 Nf3+ gxf3 Bc5#",
        pgn: "",
        orientation: "",
        title: "My missed mate in 7",
        lastMove: "f2 g3",
        tags: ["tactics", "mate"],
      },
      true
    );

    addPosition(
      {
        fen: "",
        movesString: "",
        pgn: '[Event "Berlin"]\n[Site "Berlin GER"]\n[Date "1852.??.??"]\n[EventDate "?"]\n[Round "?"]\n[Result "1-0"]\n[White "Adolf Anderssen"]\n[Black "Jean Dufresne"]\n[ECO "C52"]\n[WhiteElo "?"]\n[BlackElo "?"]\n[PlyCount "47"]\n\n1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Ba5 6.d4 exd4 7.O-O\nd3 8.Qb3 Qf6 9.e5 Qg6 10.Re1 Nge7 11.Ba3 b5 12.Qxb5 Rb8 13.Qa4\nBb6 14.Nbd2 Bb7 15.Ne4 Qf5 16.Bxd3 Qh5 17.Nf6+ gxf6 18.exf6\nRg8 19.Rad1 Qxf3 20.Rxe7+ Nxe7 21.Qxd7+ Kxd7 22.Bf5+ Ke8\n23.Bd7+ Kf8 24.Bxe7# 1-0',
        orientation: "",
        title: "",
        tags: ["famous games"],
      },
      true
    );

    addPosition(
      {
        fen: "rnb2rk1/pppq1ppp/3p4/4n3/2BQ4/8/PB3P1P/R5RK w - - 0 1",
        movesString: "Rxg7+ Kxg7 Rg1+ Kh8 Qxe5+ dxe5 Bxe5+ f6 Bxf6+ Rxf6 Rg8#",
        pgn: "",
        orientation: "",
        title: "A beautiful combination",
        tags: ["tactics"],
      },
      true
    );

    addPosition(
      {
        fen: "",
        movesString: "",
        pgn: '[Event "Paris"]\n[Site "Paris FRA"]\n[Date "1858.??.??"]\n[EventDate "?"]\n[Round "?"]\n[Result "1-0"]\n[White "Paul Morphy"]\n[Black "Duke Karl / Count Isouard"]\n[ECO "C41"]\n[WhiteElo "?"]\n[BlackElo "?"]\n[PlyCount "33"]\n\n1.e4 e5 2.Nf3 d6 3.d4 Bg4 {This is a weak move already.--Fischer} 4.dxe5 Bxf3 5.Qxf3 dxe5 6.Bc4 Nf6 7.Qb3 Qe7 8.Nc3 c6 9.Bg5 {Black is in what\'s like a zugzwang position here. He can\'t develop the [Queen\'s] knight because the pawn is hanging, the bishop is blocked because of the Queen.--Fischer} b5 10.Nxb5 cxb5 11.Bxb5+ Nbd7 12.O-O-O Rd8 13.Rxd7 Rxd7 14.Rd1 Qe6 15.Bxd7+ Nxd7 16.Qb8+ Nxb8 17.Rd8# 1-0)',
        orientation: "",
        title: "",
        tags: ["famous games"],
      },
      true
    );

    addPosition(
      {
        fen: "",
        movesString: "",
        pgn: '[Event "50th US Open"]\n[Site "Omaha, NE USA"]\n[Date "1949.07.21"]\n[EventDate "1949.??.??"]\n[Round "10"]\n[Result "1-0"]\n[White "Albert Sandrin"]\n[Black "Phil Le Cornu"]\n[ECO "A16"]\n[WhiteElo "2310"]\n[BlackElo "2220"]\n[PlyCount "55"]\n\n1. c4 Nf6 2.Nc3 d5 3.cxd5 Nxd5 4.g3 g6 5. Bg2 Be6 6. Nf3 Nxc3\n7. bxc3 Bg7 8. O-O O-O 9. d4 c6 10. e4 Bc4 11. Re1 Nd7 12. Be3\nQa5 13. Nd2 Ba6 14. Qb3 c5 15. e5 cxd4 16. cxd4 Nb6 17. Rac1\nRac8 18. Rc5 Rxc5 19. dxc5 Nd7 20.e6 Nxc5 21. exf7+ Kh8\n22. Qd5 b6 23. Bd4 Nd3 24. Rxe7 Qxd2 25. Re8 h6 26. Rxf8+ Kh7\n27. Rh8+ Bxh8 28. f8=N# 1-0',
        orientation: "white",
        title: "Underpromotion Mate",
        notes:
          "The fancy move 18. Rc5 was actually a blunder. \nNc4 would have been the accurate follow-up by black after the rook exchange.",
        tags: ["tactics"],
      },
      true
    );
  };

  const addPosition = async (data, skipDb = false) => {
    // console.log("ADD Position Called", data);
    let newPosition = {
      chess: new Chess(data.pgn.length > 0 ? data.pgn : data.fen),
      fen: data.fen,
      moves: [],
      movesVerbose: [],
      turn: 0,
      lastMove: data.lastMove,
      movesString: data.movesString,
      pgn: data.pgn,
      tags: data.tags.length > 0 ? data.tags : [],
      isGuest: props.userLoaded ? false : true,
      orientation: data.orientation,
      title: data.title,
      notes: data.notes,
      uuid: nanoid() + Timestamp.now().seconds,
      docId: data.docId,
    };

    if (newPosition.pgn.length > 0) {
      newPosition.fen = "";
    }

    let doc = null;

    // console.log(props.user, "user");
    if (skipDb === false && props.user) {
      doc = await addPositionToDb(props.user, newPosition);
      resetFields();
    }

    if (doc !== null) {
      newPosition.docId = doc.id;
    }

    props.setBoards((prevArray) => [newPosition, ...prevArray]);

    if (skipDb === false && props.user) {
      resetTags(props.boardsRef.current);
    }

    for (var t of newPosition.tags) {
      if (!allTagsRef.current.includes(t.toLowerCase())) {
        setAllTags((allTags) => [...allTags, t.toLowerCase()]);
        props.setAllTags(allTagsRef.current);
      }
    }
  };

  const setupPositions = async (currentUser) => {
    const userPositions = await getPositions(currentUser);
    // console.log(userPositions, "User positions");

    if (userPositions !== undefined) {
      userPositions.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data());

        const data = doc.data();
        // console.log(data);
        addPosition(
          {
            fen: data.fen,
            movesString: data.movesString,
            lastMove: data.lastMove,
            pgn: data.pgn,
            orientation: data.orientation,
            title: data.title,
            tags: data.tags,
            docId: doc.id,
            notes: data.notes,
          },
          true
        );
      });
    }

    props.setBoardsLoaded(true);
  };

  const addPositionButtonClicked = (board) => {
    if (pgnField !== "") {
      addPosition(
        {
          fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
          movesString: bestMovesField,
          pgn: pgnField,
          orientation: pgnOrientationRef.current ? "white" : "black",
          title: titleField,
          tags: Helper.stripArray(selectedItems),
          notes: notesField,
        },
        false
      );
    } else if (addFenField !== "") {
      addPosition(
        {
          fen: addFenField,
          movesString: bestMovesField,
          lastMove: lastMoveField,
          pgn: "",
          orientation: "",
          title: titleField,
          tags: Helper.stripArray(selectedItems),
          notes: notesField,
        },
        false
      );
    } else {
      //   addPosition(
      //     "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
      //     bestMovesValue,
      //   );
      // TODO invalid add
      toast({
        title: "Error.",
        description: "Please provide a FEN or PGN.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    onCloseAddPosition();
    // toggleAddPositionState(!addPositionState);
    setFenField("");
    setBestMovesField("");
    setPgnField("");
    setSelectedItems([]);
  };

  return (
    <div>
      {props.user !== null &&
      props.user.uid !== null &&
      props.user.uid !== undefined &&
      props.urlUid === props.user.uid ? (
        <Box
          width="70px"
          height="70px"
          style={{
            color: "#222",
            position: "fixed",
            bottom: "30px",
            right: "30px",
            borderRadius: "300px",
            backgroundColor: "#444",
            zIndex: 25,
            boxShadow: "4px 4px 20px rgba(0,0,0,0.25)",
          }}
        >
          {
            <Center
              h="70px"
              onClick={() => {
                buildPickerItems();
                onOpenAddPosition();
              }}
              style={{ cursor: "pointer" }}
            >
              <AiOutlinePlusCircle size={66} color="#ecc94b" />
            </Center>
          }
        </Box>
      ) : (
        <></>
      )}

      <Modal
        initialFocusRef={addPositionRef}
        isOpen={isOpenAddPosition}
        onClose={onCloseAddPosition}
        preserveScrollBarGap={true}
      >
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>Add Position</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                ref={addPositionRef}
                onChange={(e) => setTitleField(e.target.value)}
                placeholder="(optional) can generate from PGN"
                mb={4}
                value={titleField}
              />
            </FormControl>
            {/* <FormLabel>Add either FEN or PGN</FormLabel> */}
            <Tabs>
              <TabList>
                <Tab>FEN</Tab>
                <Tab>PGN</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FormControl>
                    <FormLabel>FEN</FormLabel>
                    <Input
                      onChange={(e) => setFenField(e.target.value)}
                      placeholder="Paste the FEN"
                      mb={4}
                      value={addFenField}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Best Moves</FormLabel>
                    <Textarea
                      mb={4}
                      onChange={(e) => setBestMovesField(e.target.value)}
                      placeholder="eg. g4 h6 Ke3 Rd4 (optional)"
                      value={bestMovesField}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Last Move</FormLabel>
                    <Input
                      onChange={(e) => setLastMoveField(e.target.value)}
                      placeholder="g1 f3 would mean Nf3 (optional)"
                      value={lastMoveField}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <FormControl>
                    <FormLabel>PGN</FormLabel>
                    <Textarea
                      onChange={(e) => setPgnField(e.target.value)}
                      placeholder="Paste the PGN"
                      mb={4}
                      value={pgnField}
                    />
                  </FormControl>

                  <FormControl mb={6}>
                    <Switch
                      mr={6}
                      float="left"
                      id="orientation"
                      defaultChecked={true}
                      onChange={(e) => {
                        setPgnOrientationField(e.target.checked);
                      }}
                      isChecked={pgnOrientation}
                    />
                    <Text float="left" htmlFor="orientation">
                      {pgnOrientationRef.current ? (
                        <>White Orientation</>
                      ) : (
                        <>Black Orientation</>
                      )}
                    </Text>
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                onChange={(e) => setNotesField(e.target.value)}
                placeholder="Add your notes (optional)"
                mb={4}
                value={notesField}
              />
            </FormControl>

            <div id="tagField">
              <FormControl float="left">
                <CUIAutoComplete
                  label="Tags"
                  placeholder="Type your own or choose (optional)"
                  onCreateItem={handleCreateItem}
                  items={pickerItems}
                  tagStyleProps={{
                    pt: 2,
                    pb: 1,
                    pl: 3,
                    px: 2,
                    fontSize: "0.9rem",
                    background: "#3a434d",
                    color: "#eee",
                  }}
                  selectedItems={selectedItems}
                  onSelectedItemsChange={(changes) =>
                    handleSelectedItemsChange(changes.selectedItems)
                  }
                />
              </FormControl>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="yellow"
              mr={3}
              onClick={(e) => addPositionButtonClicked(e)}
            >
              Add
            </Button>
            <Button
              variant="outline"
              colorScheme="yellow"
              onClick={onCloseAddPosition}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
});

export default AddPosition;
