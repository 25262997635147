import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";

import {
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalContent,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Textarea,
  Switch,
  ModalFooter,
  Button,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { CUIAutoComplete } from "chakra-ui-autocomplete";
import useStateRef from "react-usestateref";

import Helper from "./functions/Helper";

import { editPositionDb, deletePositionDb } from "./base";

const EditPosition = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    editPositionClicked,
  }));

  const [currentBoard, setCurrentBoard] = useState();

  useEffect(() => {}, [props.boards]);

  const resetFields = () => {
    onCloseEditPosition();
    setPgnOrientationField(true);
    setFenField("");
    setBestMovesField("");
    setPgnField("");
    setSelectedItems([]);
    setTitleField("");
    setNotesField("");
    setLastMoveField("");
  };
  const editPositionClicked = (board) => {
    // First clear any inputs

    resetFields();

    onOpenEditPosition();
    setTitleField(board.title);
    setNotesField(board.notes);
    setLastMoveField(board.lastMove);

    let newTags = [];
    for (var t of board.tags) {
      newTags.push({ label: t, value: t });
    }
    setSelectedItems(newTags);

    setPgnOrientationField(
      board.orientation === "white" || board.orientation === "" ? true : false
    );

    if (board.pgn.length > 0) {
      setPgnField(board.pgn);

      setTabIndex(1);
    } else {
      setFenField(board.fen);
      setBestMovesField(board.movesString);

      setTabIndex(0);
    }

    // Add to picker items for tags.
    // build

    buildPickerItems();

    setCurrentBoard(board);

    // console.log(board);
  };

  const buildPickerItems = () => {
    let newPickerItems = [...pickerItems];
    let newPickerItems2 = [];

    for (var r of newPickerItems) {
      newPickerItems2.push(r.value);
    }

    for (var boards of props.boards) {
      for (var q2 of boards.tags) {
        if (!newPickerItems2.includes(q2))
          newPickerItems.push({ value: q2, label: q2 });
      }
    }

    newPickerItems = newPickerItems.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.value === thing.value && t.label === thing.label
        )
    );

    setPickerItems(newPickerItems);
  };

  const resetTags = (newBoards) => {
    let tags = ["all"];

    for (var q of newBoards) {
      for (var q2 of q.tags) {
        if (!tags.includes(q2)) tags.push(q2);
      }
    }
    props.setAllTags(tags);
  };

  const editPositionRef = useRef();
  const {
    isOpen: isOpenEditPosition,
    onOpen: onOpenEditPosition,
    onClose: onCloseEditPosition,
  } = useDisclosure();

  const [tabIndex, setTabIndex] = useState(0);

  const [titleField, setTitleField] = useState("");

  const [fenField, setFenField] = useState("");
  const [notesField, setNotesField] = useState("");
  const [pgnField, setPgnField] = useState("");
  const [pgnOrientation, setPgnOrientationField, pgnOrientationRef] =
    useStateRef(true);
  const [bestMovesField, setBestMovesField] = useState("");
  const [lastMoveField, setLastMoveField] = useState("");

  const [hideSampleAlert, setHideSampleAlert, hideSampleAlertRef] =
    useStateRef(false);

  /////////// Tags /////////////////////
  //   const [allTags, setAllTags, allTagsRef] = useStateRef(["all"]);
  const [pickerItems, setPickerItems] = useState(Helper.defaultTags);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCreateItem = (item) => {
    // check for duplicates first
    if (!pickerItems.includes(item)) {
      setPickerItems((curr) => [...curr, item]);
    }
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
    }
  };

  ///////// end tags //////////////////

  /// Alert

  const [isOpenAlert, setIsOpenAlert] = React.useState(false);
  const onCloseAlert = () => setIsOpenAlert(false);
  const cancelRefAlert = React.useRef();

  const saveEditPositionButtonClicked = async (e) => {
    let tempBoard = {
      title: titleField,
      fen: fenField,
      movesString: bestMovesField,
      lastMove: lastMoveField,
      orientation:
        pgnField.length > 0
          ? pgnOrientation
            ? "white"
            : "black"
          : currentBoard.fen.split(" ")[1] === "w"
          ? "white"
          : "black",
      pgn: pgnField,
      tags: Helper.stripArray(selectedItems),
      notes: notesField,
      docId: currentBoard.docId,
    };

    // now change the board variable for the whole boards array.

    let newBoards = [...props.boards];

    for (var t of newBoards) {
      if (t.docId === tempBoard.docId) {
        // console.log(t);
        t.chess = null;
        t.title = tempBoard.title;
        t.fen = tempBoard.fen;
        t.movesString = tempBoard.movesString;
        t.lastMove = tempBoard.lastMove === undefined ? "" : tempBoard.lastMove;
        t.orientation = tempBoard.orientation;
        t.pgn = tempBoard.pgn;
        t.tags = tempBoard.tags;
        t.notes = tempBoard.notes === undefined ? "" : tempBoard.notes;
      }
    }

    props.setBoards(newBoards);

    // props.setBoards()
    let q = editPositionDb(props.user, tempBoard);
    resetFields();
    resetTags(newBoards);
    onCloseEditPosition();
    // if (q === undefined) {

    // }
  };

  const onClickDelete = async () => {
    resetFields();
    onCloseAlert();
    onCloseEditPosition();

    await deletePositionDb(props.user, currentBoard.docId);

    let newBoards = [...props.boards];

    newBoards = newBoards.filter((i) => i.docId !== currentBoard.docId);
    props.setBoards(newBoards);

    // Set all tags again once finish deleting.
    resetTags(newBoards);

    // Also delete cached pickerItems.

    buildPickerItems();
  };

  return (
    <>
      {/* <Button size="sm" onClick={onOpenEditPosition}>
        <Icon as={BsPencilSquare} w={4} h={4} />
      </Button> */}
      <Modal
        initialFocusRef={editPositionRef}
        isOpen={isOpenEditPosition}
        onClose={onCloseEditPosition}
        preserveScrollBarGap={true}
      >
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>Edit Position</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                ref={editPositionRef}
                onChange={(e) => setTitleField(e.target.value)}
                placeholder="(Optional)"
                value={titleField}
                mb={4}
              />
            </FormControl>
            <Tabs defaultIndex={tabIndex}>
              <TabList>
                <Tab isDisabled={currentBoard?.fen.length === 0}>FEN</Tab>
                <Tab isDisabled={currentBoard?.pgn.length === 0}>PGN</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FormControl>
                    <FormLabel>FEN</FormLabel>
                    <Input
                      onChange={(e) => setFenField(e.target.value)}
                      placeholder="Paste the FEN"
                      mb={4}
                      value={fenField}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Best Moves</FormLabel>
                    <Textarea
                      mb={4}
                      onChange={(e) => setBestMovesField(e.target.value)}
                      placeholder="(Optional) eg. g4 h6 Ke3 Rd4"
                      value={bestMovesField}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Last Move</FormLabel>
                    <Input
                      onChange={(e) => setLastMoveField(e.target.value)}
                      placeholder="g1 f3 would mean Nf3 (optional)"
                      value={lastMoveField}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <FormControl>
                    <FormLabel>PGN</FormLabel>
                    <Textarea
                      onChange={(e) => setPgnField(e.target.value)}
                      value={pgnField}
                      placeholder="Paste the PGN"
                      mb={4}
                    />
                  </FormControl>

                  <FormControl mb={6}>
                    <Switch
                      mr={6}
                      float="left"
                      id="orientation"
                      defaultChecked={pgnOrientation}
                      onChange={(e) => {
                        setPgnOrientationField(e.target.checked);
                      }}
                      isChecked={pgnOrientation}
                    />
                    <Text float="left" htmlFor="orientation">
                      {pgnOrientationRef.current ? (
                        <>White Orientation</>
                      ) : (
                        <>Black Orientation</>
                      )}
                    </Text>
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                onChange={(e) => setNotesField(e.target.value)}
                placeholder="Add your notes (optional)"
                mb={4}
                value={notesField}
              />
            </FormControl>

            <div id="tagField">
              <FormControl float="left">
                <CUIAutoComplete
                  label="Tags"
                  placeholder="Type your own or choose"
                  onCreateItem={handleCreateItem}
                  items={pickerItems}
                  tagStyleProps={{
                    pt: 2,
                    pb: 1,
                    pl: 3,
                    px: 2,
                    fontSize: "0.9rem",
                    background: "#3a434d",
                    color: "#eee",
                  }}
                  selectedItems={selectedItems}
                  onSelectedItemsChange={(changes) =>
                    handleSelectedItemsChange(changes.selectedItems)
                  }
                />
              </FormControl>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="yellow"
              mr={3}
              onClick={(e) => saveEditPositionButtonClicked(e)}
            >
              Save Edits
            </Button>

            <Button
              mr={3}
              variant="outline"
              colorScheme="yellow"
              onClick={onCloseEditPosition}
            >
              Close
            </Button>
            <Button colorScheme="red" onClick={() => setIsOpenAlert(true)}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRefAlert}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Position
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRefAlert}
                onClick={onCloseAlert}
                variant="outline"
                colorScheme="yellow"
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onClickDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
});

export default EditPosition;
