import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getPgnTitle } from "./functions/pgnParserCustom";

// import firebase from "firebase/compat/app";
import {
  collection,
  getDocs,
  updateDoc,
  setDoc,
  doc,
  getDoc,
  addDoc,
  getFirestore,
  Timestamp,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
// import "@firebase/firestore";
import "@firebase/auth";

// import { useAuthState } from "react-firebase-hooks/auth";
// import { useCollectionData } from "react-firebase-hooks/firestore";
// import {  } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCd2lApLaC-mHHdvE2eFp9BoUOz3mNY-No",
  authDomain: "chesskeep-6c192.firebaseapp.com",
  projectId: "chesskeep-6c192",
  storageBucket: "chesskeep-6c192.appspot.com",
  messagingSenderId: "654795676911",
  appId: "1:654795676911:web:a5d82db2d1cfad9d394f1a",
  measurementId: "G-8LZ6Y502NV",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

const db = getFirestore(app);

export default app;

export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const twitterProvider = new TwitterAuthProvider();

const usersCollectionRef = collection(db, "users");

export const addUser = async (user) => {
  const docRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(docRef);
  if (userDoc.exists()) {
    // console.log("User data:", userDoc.data());
  } else {
    // alert("User doesn't exist, creating in db");
    await setDoc(doc(usersCollectionRef, user.uid), {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      // metadata: user.metadata,
      // providerData: user.providerData,
      reloadUserInfo: user.reloadUserInfo,
      premium: false,
      premiumSubTime: "",
    });

    let newPos = {
      fen: "rnb2rk1/pppq1ppp/3p4/4n3/2BQ4/8/PB3P1P/R5RK w - - 0 1",
      movesString: "Rxg7+ Kxg7 Rg1+ Kh8 Qxe5+ dxe5 Bxe5+ f6 Bxf6+ Rxf6 Rg8#",
      pgn: "",
      orientation: "white",
      title: "My first position from FEN",
      notes:
        "Welcome to ChessKeep. This is your first position. Edit or delete it, then start adding! Kappa.",
      uuid: "1",
      tags: ["tactics"],
    };
    await addPositionToDb(user, newPos);

    let newPos2 = {
      fen: "",
      movesString: "",
      pgn: '[Event "Paris"]\n[Site "Paris FRA"]\n[Date "1858.??.??"]\n[EventDate "?"]\n[Round "?"]\n[Result "1-0"]\n[White "Paul Morphy"]\n[Black "Duke Karl / Count Isouard"]\n[ECO "C41"]\n[WhiteElo "?"]\n[BlackElo "?"]\n[PlyCount "33"]\n\n1.e4 e5 2.Nf3 d6 3.d4 Bg4 {This is a weak move already.--Fischer} 4.dxe5 Bxf3 5.Qxf3 dxe5 6.Bc4 Nf6 7.Qb3 Qe7 8.Nc3 c6 9.Bg5 {Black is in what\'s like a zugzwang position here. He can\'t develop the [Queen\'s] knight because the pawn is hanging, the bishop is blocked because of the Queen.--Fischer} b5 10.Nxb5 cxb5 11.Bxb5+ Nbd7 12.O-O-O Rd8 13.Rxd7 Rxd7 14.Rd1 Qe6 15.Bxd7+ Nxd7 16.Qb8+ Nxb8 17.Rd8# 1-0)',
      orientation: "white",
      title: "A Night at the Opera",
      notes: "The most famous game as an inspiration.",
      uuid: "1",
      tags: ["inspiration"],
    };
    await addPositionToDb(user, newPos2);

    return "new user";
  }
};

export const updateUser = async (user) => {
  const docRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(docRef);
  if (userDoc.exists()) {
    try {
      await updateDoc(docRef, {
        reloadUserInfo: user.reloadUserInfo,
      });
    } catch (err) {
      alert(err.message);
      return err;
    }
  }
};

export const checkUserExists = async (user) => {
  const docRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(docRef);
  return userDoc.exists() ? true : false;
};

export const testDb = async (user) => {
  const docRef = doc(db, "users", user.uid, "positions", "position1");
  const userDoc = await getDoc(docRef);
  console.log(userDoc);
};

export const testDb2 = async (user) => {
  var q = await addDoc(
    collection(usersCollectionRef, user.uid, "positions"),
    {}
  );
  console.log(q.id);
};

export const testDb3 = async (user) => {
  // let time = Timestamp.now().toDate();
  // console.log("time", time);

  const docRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(docRef);
  if (userDoc.exists()) {
    const q = query(
      collection(db, "users", user.uid, "positions"),
      orderBy("time", "desc")
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });
  }
};

export const getPositions = async (userUid) => {
  if (userUid === undefined) return;

  const docRef = doc(db, "users", userUid);

  const userDoc = await getDoc(docRef);
  if (userDoc.exists()) {
    const q = query(
      collection(db, "users", userUid, "positions"),
      orderBy("time", "asc")
    );

    const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   console.log(doc.id, " => ", doc.data());
    // });

    return querySnapshot;
  }
};

export const addPositionToDb = async (user, position) => {
  // console.log("Add" + user.uid);
  // console.log(db);
  const docRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(docRef);
  if (userDoc.exists()) {
    let title = position.title;

    if (position.title === "" && position.pgn.length > 0) {
      title = getPgnTitle(position.pgn);
    }

    let newPositions = {
      tags: position.tags,
      fen: position.fen,
      movesString: position.movesString,
      lastMove: position.lastMove !== undefined ? position.lastMove : "",
      pgn: position.pgn,
      orientation: position.orientation,
      title: title,
      notes: position.notes,
      uuid: position.uuid,
      time: Timestamp.now(),
    };

    var q = await addDoc(
      collection(usersCollectionRef, user.uid, "positions"),
      newPositions
    );

    return q;

    // console.log(q.id, "added id ");

    // console.log("positions", newPositions);

    // await updateDoc(doc(usersCollectionRef, user.uid), {
    //   positions: newPositions,
    // });

    // if (
    //   positions === undefined ||
    //   (positions !== undefined && positions.length === 0)
    // ) {
    //   // No positions exist then add one.
    //   await updateDoc(doc(usersCollectionRef, user.uid), {
    //     positions: { fen: fen, moves: moves, pgn: pgn },
    //   });
    // } else {

    // }
  }
};

export const editPositionDb = async (user, position) => {
  const docRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(docRef);
  if (userDoc.exists()) {
    const docRef = doc(db, "users", user.uid, "positions", position.docId);

    // const theDoc = await getDoc(docRef);

    // if (theDoc.exists()) {
    //   // await updateDoc(theDoc, position);
    //   console.log(theDoc);
    // }

    try {
      await updateDoc(docRef, position);
    } catch (err) {
      alert(err.message);
      return err;
    }
  }
};

export const deletePositionDb = async (user, docId) => {
  const docRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(docRef);
  if (userDoc.exists()) {
    const docRef = doc(db, "users", user.uid, "positions", docId);
    try {
      await deleteDoc(docRef);
    } catch (err) {
      alert(err.message);
      return err;
    }
  }
};

// export const checkUserExistsEmail = async (email) => {
//   const docRef = doc(db, "users", email);
//   const userDoc = await getDoc(docRef);
//   if (userDoc.exists()) {addUser
//     console.log("User data:", userDoc.data());
//   } else {
//     // console.log("User doesn't exist, creating in db");
//     await setDoc(doc(usersCollectionRef, email), {
//       email: email,
//       premium: false,
//     });
//   }
// };

// export const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     console.log(res);

//     const user = res.user;

//     await addUser(user);
//   } catch (err) {
//     console.error(err);
//     return err;
//   }
// };

// export const signInWithFacebook = async () => {
//   try {
//     const res = await signInWithPopup(auth, facebookProvider);
//     console.log(res);

//     const user = res.user;

//     await addUser(user);
//   } catch (err) {
//     console.error(err);
//     return err;
//   }
// };

// export const signInWithEmailAndPassword = async (email, password) => {
//   try {
//     await auth.signInWithEmailAndPassword(email, password);
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// export const registerWithEmailAndPassword = async (name, email, password) => {
//   try {
//     const res = await auth.createUserWithEmailAndPassword(email, password);
//     const user = res.user;
//     await db.collection("users").add({
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// export const sendPasswordResetEmail = async (email) => {
//   try {
//     await auth.sendPasswordResetEmail(email);
//     alert("Password reset link sent!");
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// export const logout = () => {
//   auth.signOut();
// };
