export default function pgnParserCustom(pgn) {
  let whiteName = "";
  let blackName = "";
  let whiteElo = 0;
  let blackElo = 0;
  let date = "";
  let winner = "";
  let chessEvent = "";
  let fen = "";

  let v = pgn.split("\n");

  let containsSetup = false;

  for (let t of v) {
    if (t.includes('[Event "')) {
      chessEvent = t.replace('[Event "', "").slice(0, -2);
    }
    if (t.includes('[White "')) {
      whiteName = t.replace('[White "', "").slice(0, -2);
    } else if (t.includes("[Black ")) {
      blackName = t.substring(t.indexOf("[Black ") + 8).replace('"]', "");
    } else if (t.includes("[WhiteElo ")) {
      whiteElo = t.substring(t.indexOf("[WhiteElo ") + 11).replace('"]', "");
    } else if (t.includes("[BlackElo ")) {
      blackElo = t.substring(t.indexOf("[BlackElo ") + 11).replace('"]', "");
    } else if (t.includes("[Date ")) {
      date = t.substring(t.indexOf("[Date ") + 7).replace('"]', "");
    } else if (t.includes("[Result ")) {
      winner = t.substring(t.indexOf("[Result ") + 9).replace('"]', "");
    } else if (t.includes('[SetUp "1"]')) {
      containsSetup = true;
    } else if (t.includes('[FEN "') && containsSetup) {
      fen = t.replace('[FEN "', "").slice(0, -2);
    }
  }

  return {
    chessEvent: chessEvent,
    whiteName: whiteName,
    blackName: blackName,
    whiteElo: whiteElo,
    blackElo: blackElo,
    date: date,
    winner: winner,
    fen: fen,
  };
}

export const getPgnTitle = (pgn) => {
  let a = pgnParserCustom(pgn);
  // console.log(a);

  if (
    (a.whiteName.length > 0 || a.blackName.length > 0) &&
    (a.blackElo > 0 || a.whiteElo > 0)
  ) {
    return (
      a.whiteName +
      " (" +
      a.whiteElo +
      ") vs " +
      a.blackName +
      " (" +
      a.blackElo +
      ") | " +
      a.date
    );
  } else if (a.whiteName.length > 0 || a.blackName.length > 0)
    return a.whiteName + " vs " + a.blackName + " | " + a.date;
  else return "PGN";
};
