export default class Helper {
  static toSeoUrl(input) {
    return input
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, ""); // Remove trailing dashes
  }

  static shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  static breakpoints = {
    sm: "480",
    md: "768",
    lg: "992",
    xl: "1280",
    xxl: "1536",
  };

  static toUrlPgnName(input) {
    // reverse the name

    // let str2 = input;
    // if (input.includes(",") && input.indexOf(",") > 0) {
    //   let str = input.replace(",", " ").split(" ")[0];
    //   //   console.log(str);
    //   str2 = input.substring(input.indexOf(str + 1));
    //   str2 = str2.split(" ")[1] + " " + str.split(" ")[0];
    // }
    return input
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, ""); // Remove trailing dashes
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static defaultTags = [
    { value: "openings", label: "Openings" },
    { value: "middlegames", label: "Middlegames" },
    { value: "endgames", label: "Endgames" },
    { value: "tactics", label: "Tactics" },
    { value: "positional play", label: "Positional Play" },
    { value: "attacking", label: "Attacking" },
    { value: "defending", label: "Defending" },
    { value: "mate", label: "Mate" },
    { value: "blunders", label: "Blunders" },
  ];

  static stripArray = (arr) => {
    let n = [];
    for (let e of arr) {
      n.push(e.value);
    }
    return n;
  };
}
