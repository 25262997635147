import React, { useState, useEffect } from "react";

import { ChakraProvider } from "@chakra-ui/react";

import Main from "./Main";
import "./App.css";
import "react-chessground/dist/styles/chessground.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/@/:urlUid" element={<Main />} />
          <Route exact path="/" element={<Main />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
