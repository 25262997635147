import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import Header from "./Header";
import Board from "./Board";
import {
  Box,
  TagLabel,
  Tag,
  Spinner,
  Center,
  Text,
  Icon,
  Button,
  Stack,
} from "@chakra-ui/react";

import { createBreakpoints } from "@chakra-ui/theme-tools";

import Helper from "./functions/Helper";

import useStateRef from "react-usestateref";

import { BsShuffle } from "react-icons/bs";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { GiExpand, GiSpades } from "react-icons/gi";
import { MdCenterFocusStrong } from "react-icons/md";

import { initializeApp } from "firebase/app";
import { onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

import { addPositionToDb, auth, testDb3 } from "./base";

import AddPosition from "./AddPosition";
import EditPosition from "./EditPosition";

const firebaseConfig = {
  apiKey: "AIzaSyCd2lApLaC-mHHdvE2eFp9BoUOz3mNY-No",
  authDomain: "chesskeep-6c192.firebaseapp.com",
  projectId: "chesskeep-6c192",
  storageBucket: "chesskeep-6c192.appspot.com",
  messagingSenderId: "654795676911",
  appId: "1:654795676911:web:a5d82db2d1cfad9d394f1a",
  measurementId: "G-8LZ6Y502NV",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Main = () => {
  const { urlUid } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0); // integer state

  const addPositionChildRef = useRef();

  const [boards, setBoards, boardsRef] = useStateRef([]);

  const [user, setUser] = useState({});
  const [userLoaded, setUserLoaded, userLoadedRef] = useStateRef(false);
  const [boardsLoaded, setBoardsLoaded] = useState(false);

  const [allTags, setAllTags, allTagsRef] = useStateRef(["all"]);
  const [activeTag, setActiveTag, activeTagRef] = useStateRef(["all"]);

  const [key, setKey, keyRef] = useStateRef(0);

  const [viewingState, setViewingState] = useState(0);
  const [isFocusMode, setIsFocusMode] = useState(false);
  const [focusModeIndex, setFocusModeIndex, focusModeIndexRef] = useStateRef(0);
  const [focusModeBoards, setFocusModeBoards] = useState([]);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    if (currentUser) setUserLoaded(true);
  });

  // useEffect(() => {
  //   const search = window.location.search;
  //   const params = new URLSearchParams(search);
  //   const foo = params.get("tags");
  //   // alert(urlUid);
  //   // alert(foo);
  // }, []);

  // useEffect(() => {
  // If homepage url is nothing, then we go to active user's profile.
  // if (user !== null && user.uid !== null && user.uid !== undefined) {
  //   let p = window.location.pathname;
  //   p = p.split("/").pop();
  //   if (p === "") {
  //     navigate("/" + user.uid, { replace: true });
  //     // window.location.reload();
  //   }
  // }
  // }, [user, userLoaded]);

  useEffect(() => {
    setupFocusMode();
  }, [userLoaded, boards, activeTag]);

  const setupFocusMode = (shuffle = false, initialIndex = 0) => {
    let b = [...boards];
    if (!activeTagRef.current.includes("all")) {
      b = b.filter((x) =>
        x.tags.some((r) => activeTagRef.current.includes(r.toLowerCase()))
      );
    }

    if (shuffle) {
      b = Helper.shuffle(b);
    }

    setFocusModeBoards(b);
    setFocusModeIndex(initialIndex);
  };

  const shuffleBoard = () => {
    let b = [...boards];

    b = Helper.shuffle(b);

    setBoards(b);
  };

  const test = () => {
    console.log("test", user);
    testDb3(user);
  };

  const clearPositions = () => {
    // setBoards(prevBoard=> (
    //   prevBoard.filter((value, i) => i !== index)
    // ))
  };

  const emptyBoards = (user) => {
    // setBoards([]);
    // setupPositions(user);
    let newval = boards.filter((c) => c.isGuest === false);
    setBoards(newval);
  };

  const addAllExistingPositionsToUser = async (newUser) => {
    let p = window.location.pathname;
    p = p.split("/").pop();
    // alert(newUser.uid, p);
    for (var e of boards) {
      if (e.isGuest && (p === "" || p === newUser.uid)) {
        await addPositionToDb(newUser, {
          fen: e.fen,
          movesString: e.movesString,
          pgn: e.pgn,
          orientation: e.orientation,
          title: e.title,
          uuid: e.uuid,
          tags: e.tags,
        });
      }
    }
  };

  const setMovesFunc = (moves, board) => {
    let newArr = [...boards];
    newArr[board].moves = moves;

    setBoards(newArr);
  };

  const setMovesVerboseFunc = (moves, board) => {
    let newArr = [...boards];
    newArr[board].movesVerbose = moves;

    setBoards(newArr);
  };

  // const sampleGamesToast = useRef()

  ///////////////////////////////////////
  // Public void Start() { }
  ///////////////////////////////////////
  // useEffect(() => {
  // calcWidth();
  //   lastMove: "",
  //   movesString: "Bh4 g5 Bg3 Bb4+",
  //   orientation: "",
  // },
  // {
  //   chess: new Chess(),
  //   fen: "r2qr3/ppp2kpp/5n2/2bP1b2/2P4P/N2n1P2/PP1KN1P1/R1BQ1B1R b - - 4 12",
  //   moves: [],
  //   movesVerbose: [],
  //   turn: 0,
  //   lastMove: "",
  //   movesString: "Qd7 Nc2 Nxd5 Nc3 Nxc3 Nd4 Nxd1",
  // loadPgn();
  // if (!window.chessEngineWorker) {
  //   window.chessEngineWorker = new Worker("stockfish.asm.js");
  // }
  // window.chessEngineWorker.postMessage("uci");
  // }, []);

  const loadPgn = (pgn, board) => {
    boards[board].chess.load_pgn(pgn.join("\n"));

    setMovesFunc(boards[board].chess.history(), board);
    setMovesVerboseFunc(boards[board].chess.history({ verbose: true }), board);

    // if (chess.turn() === "b") {
    //   setPgnWinner("white");
    //   setBoardOrientation(false);
    // } else {
    //   setPgnWinner("black");
    //   setBoardOrientation(true);
    // }

    // reset();

    // console.log(pgn);
    // console.log(currentTurnRef.current, "current turn");

    // if (pgnWinnerRef.current === "black") {
    //   setTimeout(forward(), 500);
    // }

    // stockfishCrunch(chess.fen());
    // console.log(pgn, "loaded");
  };

  const onClickTag = (e) => {
    var a = e.target.outerHTML.replace(/(<([^>]+)>)/gi, "").toLowerCase();
    if (!activeTagRef.current.includes(a)) {
      setActiveTag((c) => [...c, a.toLowerCase()]);
    } else {
      setActiveTag(activeTagRef.current.filter((c) => c !== a.toLowerCase()));
    }

    let containsMoreThanAll = false;
    for (var t of activeTagRef.current) {
      if (t.toLowerCase() !== "all") {
        containsMoreThanAll = true;
      }
    }

    if (a === "all" && containsMoreThanAll) {
      setActiveTag(["all"]);
    } else if (containsMoreThanAll) {
      setActiveTag(activeTagRef.current.filter((c) => c !== "all"));
    }

    // rerender all boards.

    // for (var t of boardsRef.current) {
    //   t.turn = 0;
    // }
    setKey(keyRef.current + 1);

    // let newBoards = [...boards];
    // setBoards(newBoards);
  };

  const checkFiltered = (board) => {
    return (
      activeTagRef.current.includes("all") ||
      board.tags.some((r) => activeTagRef.current.includes(r.toLowerCase()))
    );
  };

  const editPositionRef = React.useRef();
  const onClickEdit = (board) => {
    editPositionRef.current.editPositionClicked(board);
  };

  const renderBoards = () => {
    let a = null;

    if (!isFocusMode) {
      a = boards.map((board, i) => (
        <Board
          filtered={checkFiltered(board)}
          user={user}
          userLoadedRef={userLoadedRef}
          boards={boards}
          board={board}
          setBoards={setBoards}
          key={board.uuid + keyRef.current}
          onClickEdit={onClickEdit}
          isFocusMode={isFocusMode}
          onFocusClick={onFocusClick}
          setIsFocusMode={setIsFocusMode}
          focusModeNextBoard={focusModeNextBoard}
        />
      ));
    } else {
      let b = focusModeBoards.slice(
        focusModeIndexRef.current,
        focusModeIndexRef.current + 1
      );

      a = b.map((board, i) => (
        <Board
          filtered={checkFiltered(board)}
          user={user}
          userLoadedRef={userLoadedRef}
          boards={boards}
          board={board}
          setBoards={setBoards}
          key={keyRef.current}
          onClickEdit={onClickEdit}
          isFocusMode={isFocusMode}
          onFocusClick={onFocusClick}
          setIsFocusMode={setIsFocusMode}
          focusModeNextBoard={focusModeNextBoard}
        />
      ));
      // console.log(b);
    }

    return a;
  };

  const onFocusClick = (uuid) => {
    console.log("click", isFocusMode);
    if (!isFocusMode) {
      let index = boardsRef.current.findIndex((x) => x.uuid === uuid);
      if (index !== -1) {
        setupFocusMode(false, index);
        setIsFocusMode(true);
      } else {
        setupFocusMode(false);
        setIsFocusMode(true);
      }
    }
  };

  const prevEnabled = () => {
    return focusModeIndexRef.current > 0;
  };

  const nextEnabled = () => {
    return focusModeIndexRef.current < focusModeBoards.length - 1;
  };

  const focusModePrevBoard = () => {
    if (prevEnabled()) {
      setFocusModeIndex(focusModeIndexRef.current - 1);
    }
  };

  const focusModeNextBoard = () => {
    if (nextEnabled()) {
      setFocusModeIndex(focusModeIndexRef.current + 1);
    }
  };

  const breakpointsPx = createBreakpoints({
    sm: "480",
    md: "768",
    lg: "992",
    xl: "1280",
    xxl: "1536",
  });

  const calcWidth = (extraWidth) => {
    let a = "";

    if (window.innerWidth > breakpointsPx.xxl) {
      a = isFocusMode ? "260px" : "300px";
    } else if (window.innerWidth > breakpointsPx.xl) {
      a = isFocusMode ? "260px" : "300px";
    } else if (window.innerWidth > breakpointsPx.lg) {
      a = isFocusMode ? "220px" : "300px";
    } else if (window.innerWidth > breakpointsPx.md) {
      a = isFocusMode ? "220px" : "350px";
    } else if (window.innerWidth > breakpointsPx.sm) {
      a = "220px";
    }
    return a;
  };

  return (
    <>
      <Header
        addAllExistingPositionsToUser={addAllExistingPositionsToUser}
        emptyBoards={emptyBoards}
        userLoaded={userLoaded}
      />

      {window.location.pathname !== "/" ? (
        <Box mb={5} pt={5}>
          <Center>
            <Stack direction="row" spacing={4}>
              <Button
                leftIcon={isFocusMode ? <GiExpand /> : <GiSpades />}
                colorScheme="yellow"
                variant={isFocusMode ? "ghost" : "ghost"}
                onClick={() => setIsFocusMode(!isFocusMode)}
              >
                {isFocusMode ? (
                  <>Exit Flash Cards Mode</>
                ) : (
                  <>Enter Flash Cards Mode</>
                )}
              </Button>

              <Button
                leftIcon={<BsShuffle />}
                colorScheme="yellow"
                variant={isFocusMode ? "ghost" : "ghost"}
                // display={isFocusMode ? "block" : "none"}
                onClick={() =>
                  isFocusMode ? setupFocusMode(true) : shuffleBoard()
                }
              >
                Shuffle
              </Button>
            </Stack>

            {/* <HStack color="#ccc" ml={6} transform="scale(1.3)">
            <Icon as={AiOutlineEye} color="#D69E2E" mr={-1} />
            <Badge
              colorScheme="yellow"
              cursor="pointer"
              fontWeight="bold"
              onClick={() => setViewingState(0)}
              variant={viewingState === 0 ? "subtle" : "outline"}
              size="xl"
            >
              All
            </Badge>

            <Badge
              colorScheme="yellow"
              fontWeight="bold"
              variant={viewingState === 1 ? "subtle" : "outline"}
              cursor="pointer"
              onClick={() => setViewingState(1)}
            >
              FEN
            </Badge>

            <Badge
              colorScheme="yellow"
              fontWeight="bold"
              variant={viewingState === 2 ? "subtle" : "outline"}
              cursor="pointer"
              onClick={() => setViewingState(2)}
            >
              PGN
            </Badge>
          </HStack> */}
          </Center>
        </Box>
      ) : (
        <></>
      )}

      {/* {isFocusMode ? (
        <Center>
          <HStack pb={6}>
            <Badge
              onClick={() => {
                focusModePrevBoard();
              }}
            >
              <ArrowLeftIcon w={4} h={4} />
            </Badge>

            <Badge size="sm">
              <ArrowRightIcon
                w={4}
                h={4}
                onClick={() => {
                  focusModeNextBoard();
                }}
              />
            </Badge>
          </HStack>
        </Center>
      ) : (
        <></>
      )} */}

      <Center>
        <Box
          pt={5}
          mb={5}
          ml={4}
          mr={4}
          display={window.location.pathname === "/" ? "none" : "block"}
        >
          {allTagsRef.current.map((tag, i) => (
            <Tag
              mb={2}
              size="lg"
              key={i}
              variant="solid"
              background={
                activeTagRef.current.includes(tag.toLowerCase())
                  ? "#4e6580"
                  : "#3a434d"
              }
              cursor="pointer"
              mr={2}
              userSelect="none"
              onClick={(e) => onClickTag(e)}
            >
              <TagLabel>{Helper.toTitleCase(tag)}</TagLabel>
            </Tag>
          ))}
        </Box>
      </Center>

      <Box
        display={!boardsLoaded ? "block" : "none"}
        style={{ height: "100vh" }}
      >
        <Spinner
          size="xl"
          color="#ecc94b"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
          }}
        />
      </Box>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyItems="center"
        justifyContent="center"
      >
        <Icon
          as={AiOutlineLeft}
          w={16}
          h={16}
          color={prevEnabled() ? "#bbb" : "#444"}
          onClick={focusModePrevBoard}
          top={calcWidth()}
          position="relative"
          cursor={prevEnabled() ? "pointer" : "default"}
          userSelect="none"
          display={isFocusMode && focusModeBoards.length > 0 ? "block" : "none"}
        />
        {renderBoards()}
        <Icon
          as={AiOutlineRight}
          w={16}
          h={16}
          color={nextEnabled() ? "#bbb" : "#444"}
          onClick={focusModeNextBoard}
          top={calcWidth()}
          position="relative"
          cursor={nextEnabled() ? "pointer" : "default"}
          userSelect="none"
          display={isFocusMode && focusModeBoards.length > 0 ? "block" : "none"}
        />
      </Box>

      {
        // .filter(
        //   (n) =>
        //     activeTagRef.current.includes("all") ||
        //     n.tags.some((r) =>
        //       activeTagRef.current.includes(r.toLowerCase())
        //     )
        // )}
        /* <Button
        float="left"
        colorScheme="yellow"
        mr={6}
        variant="outline"
        onClick={test}
      >
        Test
      </Button> */
      }

      {window.location.pathname === "/" ? (
        <>
          <Center>
            <Box
              w="80vw"
              mt={12}
              // border="1px solid #aaa"
              borderRadius="10px"
              maxWidth="700px"
            >
              <Text color="#ddd">
                <Text fontSize="25px" color="rgb(236, 201, 75);">
                  About ChessKeep
                </Text>
                <br />
                Keep your Chess positions on cloud and play through them via
                guessing the right move, or flash-card training. Great for
                improving your opening repertoire, mastering endgame positions
                or storing famous games to study / appreciate. The sky's the
                limit!
                <br />
                <br />
                Elevator Pitch: Google Keep or Trello, except for Chess.
                <br />
                <br />
                <Text mt={4} fontSize="25px" color="rgb(236, 201, 75);">
                  How do I add positions?
                </Text>
                <br />
                {userLoaded ? (
                  <>
                    {" "}
                    To add or view positions, click this button or click your
                    profile icon on the top right to go to your Positions. Then
                    you can click the (+) icon on the bottom-right to start
                    adding.
                    <br />
                    <br />
                    💡 Hint: You can also copy/paste your positions url if you
                    want to share your collection of positions to others. Here's
                    my link for example:{" "}
                    <a href="/@/RwB4j55598MGO6meWOhHC14S0u62">Link</a>
                    <br />
                    <br />
                    <a href={"/@/" + user.uid}>
                      <Button mb={6} variant="outline" colorScheme="yellow">
                        View My Positions
                      </Button>
                    </a>
                  </>
                ) : (
                  <>
                    {" "}
                    Please sign in first, or click this button to get a taste of
                    what it looks like. After adding, try moving the pieces
                    around as well to guess the right move.
                    <br />
                    <br />
                    <Button
                      mb={6}
                      variant="outline"
                      colorScheme="yellow"
                      onClick={() =>
                        addPositionChildRef.current.addSamplePositionsRef()
                      }
                    >
                      Add Sample Positions
                    </Button>
                    <br />
                    Alternatively, you can look at my own saved positions:{" "}
                    <a href="/@/RwB4j55598MGO6meWOhHC14S0u62">Link</a>
                  </>
                )}
              </Text>
              <br />

              <Text color="#ddd">
                <Text mt={4} fontSize="25px" color="rgb(236, 201, 75);">
                  Why was it made?
                </Text>
                <br />
                Being a chess player who is stuck at a certain rating can be
                difficult and frustrating. Most of the time, it takes a lot of
                hard work and dedication to get to the next level.
                <br />
                <br />
                I had an idea one day: I always use Google Keep for my notes and
                Trello for my projects' notes. So why don't I do the same thing
                for chess? Like with Google Keep, it will serve as an extension
                of my memory, so I won't have to stress about the position
                anymore, and I won't lose sleep thinking about it.
                <br />
                <br /> As a chess player, I enjoy working on openings most, and
                I often compare them to playing a roguelike game or experiencing
                groundhog day (hopefully you know what I mean; if not, it simply
                means that you keep playing the same day over and over again,
                whilst retaining your memories of the previous day).
                <br />
                <br />
                Death doesn't mean the end, it just means you know where you
                went wrong in this run and next time you will not make the same
                mistake twice. Easier said than done, no one has perfect memory.
                🤦
                <br />
                <br />
                This is where ChessKeep comes in. So for example, I'm playing an
                opening that I love and my opponent does something completely
                unexpected and different that I have not studied. Then I happen
                to make a blunder in response. <br />
                <br />
                After the game, I will now add this position to ChessKeep and
                give it the right moves as the engine recommends. Now I have
                this position documented and can be referred to at any time as
                an extension of my memory. And I can run flash-card training on
                positions similar to this with a click of a button, so I can
                really hammer it into my memory if I want to.
                <br />
                <br />
                Of course, this is definitely not only limited to openings. You
                can do it for any phase of the game, and you can even add PGNs
                for further inspiration. Try adding your best games too for
                extra motivation. The sky's the limit on how you use this and if
                you are a visual learner like me, I trust that it will help you
                greatly improve your game.
                <br />
                <br />
                <Text mt={4} fontSize="25px" color="rgb(236, 201, 75);">
                  Challenge
                </Text>
                <br />
                Here's a 30 day challenge for you. <br />
                <br />
                ➡️ Every day, play at least 5 games (blitz or rapid are the best
                for this), and on the very first mistake you make, go to your
                positions on ChessKeep and add this position's FEN code and
                input the correct moves to the "Best Moves" field.
                <br />
                <br />
                💡 Hint: It's better if the opening you play is offbeat as well,
                because chances are you will know the opening better than your
                opponent if you use this technique.
                <br />
                <br />
                ➡️ Every week run flash card training with your collection of
                positions at least once (Click Focus Mode then click Shuffle and
                play through the positions).
                <br />
                <br />
                ➡️ By the end of the month it would be amazing if you could
                share if this technique has helped you gain rating or not.
                Please tell us your results on our Discord:{" "}
                <a href="https://discord.gg/yEuVxfTWsR" target="_blank">
                  https://discord.gg/yEuVxfTWsR
                </a>
                <br />
                <br />
                <Text mt={4} fontSize="25px" color="rgb(236, 201, 75);">
                  How to copy PGN or FEN?
                </Text>
                <br />
                Lichess: Go to the analysis board of your game and at the bottom
                tabs click on FEN &amp; PGN tab. From there you can copy either
                to the Add Position popup.
                <br />
                <br />
                Chess.com: Go to the game review of your game and at the bottom
                right click the small download icon. From there you can copy the
                FEN or PGN to the Add Position popup.
                <br />
                <br />
                💡 Hint: A correct FEN code looks like this:
                rnb2rk1/pppq1ppp/3p4/4n3/2BQ4/8/PB3P1P/R5RK w - - 0 1. Please
                remove any brackets or quotes.
                <br />
                <br />
                <Text mt={4} fontSize="25px" color="rgb(236, 201, 75);">
                  Special Thanks
                </Text>
                <br />
                Pawel of{" "}
                <a href="https://chessvision.ai" target="_blank">
                  Chessvision.ai
                </a>{" "}
                for guiding me on the development side.
                <br />
                <br />
                <a
                  href="https://github.com/ruilisi/react-chessground"
                  target="_blank"
                >
                  Chessground
                </a>{" "}
                for the great plugin.
              </Text>
            </Box>
          </Center>
        </>
      ) : (
        <></>
      )}

      <AddPosition
        ref={addPositionChildRef}
        boards={boards}
        boardsRef={boardsRef}
        user={user}
        urlUid={urlUid}
        userLoadedRef={userLoadedRef}
        setBoards={setBoards}
        setBoardsLoaded={setBoardsLoaded}
        setAllTags={setAllTags}
      />

      <EditPosition
        user={user}
        boards={boards}
        ref={editPositionRef}
        setBoards={setBoards}
        setAllTags={setAllTags}
      />
    </>
  );
};

export default Main;
