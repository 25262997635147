import React, { useState, useEffect } from "react";

import useStateRef from "react-usestateref";
import {
  Box,
  Text,
  Icon,
  Button,
  VStack,
  HStack,
  Center,
  Tooltip as TooltipChakra,
  Stack,
} from "@chakra-ui/react";

import ClampLines from "react-clamp-lines";

import queen from "./images/wQ.svg";
import rook from "./images/wR.svg";
import bishop from "./images/wB.svg";
import knight from "./images/wN.svg";

import { MdCenterFocusStrong, MdNotes } from "react-icons/md";
import { GiExpand, GiSpades } from "react-icons/gi";

import { useToast } from "@chakra-ui/toast";

import { createBreakpoints } from "@chakra-ui/theme-tools";

import pgnParserCustom, { getPgnTitle } from "./functions/pgnParserCustom";
import pgnParser from "pgn-parser";

import Chess from "chess.js";

import Chessground from "react-chessground";

import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { FiRotateCcw } from "react-icons/fi";
import { BsPencilSquare } from "react-icons/bs";

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const Board = (props, { i, onClickEdit }) => {
  const [value, setValue] = useState(0);

  const forceUpdate = () => {
    setValue((value) => value + 1);
  };

  const toast = useToast();

  const [pendingMove, setPendingMove] = useState();
  const [selectVisible, setSelectVisible] = useState(false);

  const [wrongMoveCount, setWrongMoveCount] = useState(0);

  const [turn, setTurn, turnRef] = useStateRef(0);

  const [moves, setMoves, movesRef] = useStateRef([]);

  const [movesVerbose, setMovesVerbose, movesVerboseRef] = useStateRef([]);

  const [currentPlayerTurn, setCurrentPlayerTurn] = useState(false);

  const [chess, setChess] = useState(new Chess());

  const [pgn, setPgn, pgnRef] = useStateRef("");

  const [orientation, setOrientation] = useState("");

  const [tempFen, setTempFen] = useState("");

  // const toast = useToast();

  // const orientation = () => {
  //   return boardOrientation;
  // };

  const [lastMove, setLastMove] = useState();

  const [fen, setFen] = useState("");

  const [ran, setRan, ranRef] = useStateRef(false);

  const [loaded, setLoaded] = useState(false);

  const [pgnData, setPgnData] = useState();

  const [parsedPgn, setParsedPgn] = useState();

  useEffect(() => {
    // if (!props.userLoaded) return;
    // setRan(true);
    // console.log(props.board);
    setPgn(props.board.pgn);

    let pgnData = pgnParserCustom(props.board.pgn);

    if (pgnData !== undefined && pgnData !== null) {
      setPgnData(pgnData);
    }

    // console.log(pgnData);
    // SetUp=1 Positions
    if (pgnData.fen.length > 0) {
      chess.load(pgnData.fen);
      setFen(pgnData.fen);
      try {
        let p = pgnParser.parse(props.board.pgn);
        let movesString = "";
        for (var i = 0; i < p[0].moves.length; i++) {
          movesString += p[0].moves[i].move + " ";
        }
        giveMoves(movesString);
        resetFen();
      } catch (err) {
        alert(err.message);
      }
    } else if (props.board.pgn.length > 0) {
      try {
        let p = pgnParser.parse(props.board.pgn);
        setParsedPgn(p[0]);

        // console.log(p);
      } catch (err) {
        console.log(err.message, props.board.pgn);
      }
      chess.load_pgn(props.board.pgn);

      setMoves(chess.history());
      setMovesVerbose(chess.history({ verbose: true }));
      setFen(chess.fen());
      reset();
    } else if (
      props.board.movesString.length > 0 ||
      props.board.fen.length > 0
    ) {
      //   console.log(props.board.fen);
      chess.load(props.board.fen);
      setFen(props.board.fen);
      // console.log(props.board.title);
      giveMoves(props.board.movesString);
      resetFen();
    }

    setLoaded(true);
    setTempFen(props.board.fen);
  }, [props.board]);

  useEffect(() => {
    if (loaded && props.board.fen.length > 0 && props.board.pgn.length === 0) {
      giveMoves(props.board.movesString, true);
    }
  }, [props.board.movesString]);

  useEffect(() => {
    if (
      loaded &&
      props.board.lastMove !== undefined &&
      props.board.lastMove.length > 0
    ) {
      let v = props.board.lastMove.split(" ");

      if (v.length === 2) {
        setLastMove(v);
      }
    }
  }, [props.board.lastMove, loaded]);

  // useEffect(() => {
  //   if (loaded && props.board.fen.length > 0 && props.board.pgn.length === 0) {
  //     setChess(new Chess(props.board.fen));
  //     setMoves([]);
  //     setMovesVerbose([]);
  //   }
  // }, [props.board.fen]);

  // useEffect(() => {
  //   if (loaded && props.board.pgn.length > 0) {
  //     chess.load_pgn(props.board.pgn);

  //     setMoves(chess.history());
  //     setMovesVerbose(chess.history({ verbose: true }));
  //     setFen(chess.fen());
  //     reset();
  //   }
  // }, [props.board.pgn]);

  // reset();

  // console.log(props.board.pgn.length > 0);
  // if (props.board.pgn.length > 0) {
  //   console.log(props.board.pgn);
  // }

  // if (props.board.pgn.length > 0) {
  //   reset();
  // } else if (
  //   props.board.movesString.length > 0 &&
  //   props.board.pgn.length === 0
  // ) {
  //   //   resetFen();
  //   //   console.log("reset fen");
  // }

  // setMoves(props.board.moves);
  // setMovesVerbose(props.board.movesVerbose);

  //   useEffect(() => {
  //     console.log(moves.length, "moves");
  //   }, [moves]);

  const giveMoves = (v, isEditing = false) => {
    // remove the move nubmers, replace tabs with spaces, then remove duplicate spaces.
    v = v
      .replace(/([0-9]*[.]{3}|[0-9]*[.])/g, "")
      .replace(/\t/g, " ")
      .replace(/\s\s+/g, " ");

    if (isEditing) {
      setChess(new Chess(props.board.fen));
      setMoves([]);
      setMovesVerbose([]);
    }
    let arr = v.split(" ");

    for (const element of arr) {
      chess.move(element);
      // console.log("/" + element + "/");
    }

    setMoves(chess.history());
    setMovesVerbose(chess.history({ verbose: true }));

    for (var i = 0; i < arr.length; i++) {
      chess.undo();
    }

    if (isEditing) {
      setFen(chess.fen());

      setTurn(0);
      setLastMove([]);

      // for (var i = 0; i < movesCount - 1; i++) {
      //   forward();
      // }
    }

    // console.log(movesRef.current, movesVerboseRef.current);

    // console.log(chess.fen(), "Reset moves done");
  };

  const checkEndOfGame = (offset = 0) => {
    if (movesRef.current.length <= turnRef.current + offset) {
      let t = "Fin. " + pgnData.winner;

      if (props.isFocusMode) t += "Going to next position (flash cards mode).";

      toast({
        title: t,
        description: "",
        status: "success",
        duration: 2000,
        position: "bottom",
        isClosable: false,
      });

      if (props.isFocusMode) {
        setTimeout(() => {
          props.focusModeNextBoard();
        }, 2000);
      }
    }
  };

  const forward = () => {
    if (movesRef.current.length < turnRef.current + 1) return;
    chess.move(movesRef.current[turnRef.current]);
    setFen(chess.fen());
    setLastMove([
      movesVerboseRef.current[turnRef.current].from,
      movesVerboseRef.current[turnRef.current].to,
    ]);

    if (parsedPgn && parsedPgn.moves[turnRef.current].comments.length > 0) {
      toast.closeAll();
      toast({
        title: "Comment",
        description: parsedPgn.moves[turnRef.current].comments[0].text,
        status: "info",
        duration:
          parsedPgn.moves[turnRef.current].comments[0].text.length * 50 + 1000,
        position: "bottom",
        isClosable: true,
      });
    }

    setTurn(turnRef.current + 1);

    setCurrentPlayerTurn(!currentPlayerTurn);
    // stockfishCrunch(chess.fen());

    checkEndOfGame();
  };

  const backward = () => {
    if (turn === 0) return;
    chess.undo();
    setFen(chess.fen());
    setTurn(turn - 1);
    if (turn === 0) {
      setLastMove([]);
      return;
    }
    if (turn - 2 > 0) {
      setLastMove([movesVerbose[turn - 2].from, movesVerbose[turn - 2].to]);
    }
    // stockfishCrunch(chess.fen());
  };

  const reset = () => {
    chess.reset();
    setFen(chess.fen());

    setTurn(0);
    setLastMove([]);
  };

  const resetFen = () => {
    // console.log("reset fen", chess.fen());
    for (var i = 0; i < moves.length; i++) {
      chess.undo();
    }
    setLastMove([]);
    setFen(chess.fen());

    setTurn(0);
  };

  const breakpoints = createBreakpoints({
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    xxl: "96em",
  });

  const breakpointsPx = createBreakpoints({
    sm: "480",
    md: "768",
    lg: "992",
    xl: "1280",
    xxl: "1536",
  });

  const calcWidth = (extraWidth) => {
    let a = "";

    if (window.innerWidth > breakpointsPx.xxl) {
      a = props.isFocusMode ? "500px" : "300px";
    } else if (window.innerWidth > breakpointsPx.xl) {
      a = props.isFocusMode ? "500px" : "300px";
    } else if (window.innerWidth > breakpointsPx.lg) {
      a = props.isFocusMode ? "400px" : "300px";
    } else if (window.innerWidth > breakpointsPx.md) {
      a = props.isFocusMode ? "400px" : "350px";
    } else if (window.innerWidth > breakpointsPx.sm) {
      a = "350px";
    } else {
      a = "300px";
    }
    return a;
  };

  const promotion = (e) => {
    const from = pendingMove[0];
    const to = pendingMove[1];

    // console.log(movesVerboseRef.current[turnRef.current], "promotion move");

    if (movesVerboseRef.current[turnRef.current].promotion === e) {
      chess.move({ from, to, promotion: e });
      setFen(chess.fen());
      setLastMove([from, to]);
      setSelectVisible(false);
      // console.log(movesRef.current.length, turnRef.current);

      setTimeout(forward2(), 500);
    } else {
      chess.move({ from, to, promotion: e });

      setFen(chess.fen());
      // let tempFen = chess.fen();

      // console.log("Not the right promotion move, get stockfish to evaluate");

      chess.undo();
      setFen(chess.fen());

      setLastMove([]);

      if (turnRef.current > 0)
        setLastMove([movesVerbose[turn - 1].from, movesVerbose[turn - 1].to]);

      // if (useEngine) stockfishCrunchWrongMove(tempFen);
      setSelectVisible(false);
    }

    // setTimeout(randomMove, 500);
  };

  const forward2 = () => {
    if (movesRef.current.length - 1 === turnRef.current) {
      //55 54)movesRef.current.length, turnRef.current
      // it's the end of the position, so forward only once.
      forward();
      return;
    }
    if (
      movesRef.current.length < turnRef.current + 2 &&
      movesRef.current.length >= turnRef.current + 1
    ) {
      chess.move(moves[turnRef.current]);
      setFen(chess.fen());

      // stockfishCrunch(chess.fen());
      return;
    }
    if (movesRef.current.length < turnRef.current + 2) {
      return;
    }

    if (
      turnRef.current >= 0 &&
      parsedPgn &&
      parsedPgn.moves[turnRef.current + 1].comments.length > 0
    ) {
      toast.closeAll();
      toast({
        title: "Comment",
        description: parsedPgn.moves[turnRef.current + 1].comments[0].text,
        status: "info",
        duration:
          parsedPgn.moves[turnRef.current + 1].comments[0].text.length * 50 +
          1000,
        position: "bottom",
        isClosable: true,
      });
    }

    if (
      turnRef.current >= 0 &&
      parsedPgn &&
      parsedPgn.moves[turnRef.current].comments.length > 0
    ) {
      toast.closeAll();
      toast({
        title: "Comment",
        description: parsedPgn.moves[turnRef.current].comments[0].text,
        status: "info",
        duration:
          parsedPgn.moves[turnRef.current].comments[0].text.length * 50 + 1000,
        position: "bottom",
        isClosable: true,
      });
    }

    chess.move(movesRef.current[turnRef.current + 1]);
    setFen(chess.fen());

    setLastMove([
      movesVerboseRef.current[turnRef.current + 1].from,
      movesVerboseRef.current[turnRef.current + 1].to,
    ]);

    setTurn(turnRef.current + 2);

    checkEndOfGame();

    // stockfishCrunch(chess.fen());
  };

  const onMove = async (from, to) => {
    let sel = document.getSelection();
    sel.removeAllRanges();

    if (!(turnRef.current < movesVerboseRef.current.length)) {
      if (turn > 0)
        setLastMove([movesVerbose[turn - 1].from, movesVerbose[turn - 1].to]);
      return;
    }

    // console.log(turnRef.current, movesVerboseRef.current.length);
    if (
      from === movesVerboseRef.current[turnRef.current].from &&
      to === movesVerboseRef.current[turnRef.current].to
    ) {
      // setWrongMoveCount(0);
      // setLastMove([]);
      // console.log("correct", from, to);
      for (let i = 0, len = movesVerbose.length; i < len; i++) {
        /* eslint-disable-line */
        if (
          movesVerboseRef.current[i].flags.indexOf("p") !== -1 &&
          movesVerboseRef.current[i].from === from
          // movesVerboseRef.current[turnRef.current].piece === "p"
        ) {
          setPendingMove([from, to]);
          setSelectVisible(true);
          return;
        }
      }

      if (chess.move({ from, to, promotion: "x" })) {
        setFen(chess.fen());
        // checkEndOfGame(1);
        setTimeout(forward2, 500);
        setWrongMoveCount(0);

        // if (turnColor() !== pgnWinnerRef.current) {
        //   setWrongMoveCount(0);
        // setComboTextVisible(comboTextRef.current === -1 ? false : true);

        // setComboText(comboTextRef.current + 1);

        // if (!comboTextIsSettingVisibleRef.current) {
        //   let turn = currentTurnRef.current;
        //   setComboTextIsSettingVisible(true);
        //   setTimeout(() => {
        //     if (
        //       comboTextVisibleRef.current &&
        //       turn === currentTurnRef.current
        //     ) {
        //       setComboTextVisible(false);
        //       setComboTextIsSettingVisible(false);
        //     }
        //   }, 5000);
        // }
        // }

        // setLastMove([from, to])
        // setTimeout(randomMove, 500)
      }

      // const moves = chess.moves({ verbose: true })
      // for (let i = 0, len = moves.length; i < len; i++) { /* eslint-disable-line */
      //   if (moves[i].flags.indexOf("p") !== -1 && moves[i].from === from) {
      //     setPendingMove([from, to])
      //     setSelectVisible(true)
      //     return
      //   }
      // }
      // if (chess.move({ from, to, promotion: "x" })) {
      //   setFen(chess.fen())
      //   setLastMove([from, to])
      //   setTimeout(randomMove, 500)
      // }

      //     //   checkEndOfGame();
    } else {
      // chess.move({ from, to });

      // setFen(chess.fen());

      //

      // chess.undo();
      // setFen(chess.fen());

      // setLastMove([]);
      // if (turn > 0)
      //   setLastMove([movesVerbose[turn - 1].from, movesVerbose[turn - 1].to]);

      // chess.undo();

      // chess.move({ from, to });

      // setFen(chess.fen());

      // chess.undo();
      // setFen(chess.fen());

      setLastMove([]);
      if (turn > 0)
        setLastMove([movesVerbose[turn - 1].from, movesVerbose[turn - 1].to]);

      if (wrongMoveCount >= 2) {
        if (wrongMoveCount === 2) {
          toast({
            title: "Hint",
            description: movesVerboseRef.current[turnRef.current].from,
            status: "info",
            duration: 2000,
            position: "bottom",
            isClosable: false,
          });
        }

        setLastMove([
          movesVerboseRef.current[turnRef.current].from,
          movesVerboseRef.current[turnRef.current].from,
        ]);
      }

      // setValue(value + 1);
      setWrongMoveCount(wrongMoveCount + 1);
      // setTimeout(setWrongMoveCount(wrongMoveCount + 1), 500);

      // if (turnRef.current > 0) {
      //   setLastMove([
      //     movesVerboseRef.current[turnRef.current - 1].from,
      //     movesVerboseRef.current[turnRef.current - 1].to,
      //   ]);
      // }
    }
  };

  const onForwardClick = (i) => {
    forward(i);
  };

  const onBackClick = (v) => {
    // console.log(chess.header())

    backward(v);
  };

  const onResetClick = (i) => {
    if (pgnData.fen.length > 0) {
      resetFen();
    } else if (props.board.pgn.length > 0) {
      reset();
    } else {
      resetFen(i);
    }
  };

  const getTitleText = (i) => {
    return props.board.title === ""
      ? props.board.pgn.length > 0
        ? getPgnTitle(props.board.pgn)
        : "FEN"
      : props.board.title;
  };

  const getOrientation = () => {
    let a = "white";
    if (props.board.pgn.length > 0) {
      if (props.board.orientation === "") {
        a = "white";
      } else {
        a = props.board.orientation;
      }
    }
    if (props.board.fen.length > 0) {
      a = props.board.fen.split(" ")[1] === "w" ? "white" : "black";
    }
    return a;
  };

  const calcMovable = () => {
    const dests = new Map();
    chess.SQUARES.forEach((s) => {
      const ms = chess.moves({ square: s, verbose: true });
      if (ms.length)
        dests.set(
          s,
          ms.map((m) => m.to)
        );
    });
    return {
      free: false,
      dests,
      // color: chess.turn() === "w" ? "white" : "black",
    };
  };

  const turnColor = () => {
    return chess.turn() === "w" ? "white" : "black";
  };

  const showNotesCond = () => {
    return props.board.notes === undefined || props.board.notes.length === 0;
  };

  return (
    <div className={props.filtered ? "board opened" : "board closed"}>
      <VStack
        borderRadius="lg"
        borderWidth="1px"
        borderColor="#444"
        margin="20px"
        style={{ boxShadow: "4px 4px 20px rgba(0,0,0,0.25)" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="52px"
          padding="24px 12px 0 0"
          width={calcWidth()}
        >
          <TooltipChakra openDelay={750} label={getTitleText(props.i)}>
            <Text color="#aaa" width={calcWidth()}>
              <ClampLines
                text={getTitleText(i)}
                lines={2}
                buttons={false}
                ellipsis="..."
                moreText=""
                lessText=""
                className="custom-class"
                innerElement="p"
              />
              {/* {getTitleText(i)} */}
            </Text>
          </TooltipChakra>
        </Box>
        <Chessground
          width={calcWidth()}
          height={calcWidth()}
          orientation={getOrientation()}
          turnColor={orientation === "white" ? "w" : "b"}
          lastMove={lastMove}
          fen={fen}
          onMove={onMove}
          movable={calcMovable()}
          style={{
            margin: "20px",
            float: "left",
            color: "white",
            userSelect: "none",
          }}
        />

        <Box
          width="20vw"
          height="10vw"
          position="fixed"
          minWidth="100px"
          background="white"
          // top="5vw"
          marginLeft="auto"
          marginRight="auto"
          left="50%"
          top="50%"
          transform="translate(-50%,-50%)"
          zIndex="99999"
          borderRadius="10px"
          style={{
            maxWidth: "200px",
            maxHeight: "150px",
            textAlign: "center",
            cursor: "pointer",
            display: selectVisible ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction="row">
            <Box role="presentation" onClick={() => promotion("q")}>
              <img src={queen} alt="" style={{ width: 50 }} />
            </Box>
            <Box role="presentation" onClick={() => promotion("r")}>
              <img src={rook} alt="" style={{ width: 50 }} />
            </Box>
            <Box role="presentation" onClick={() => promotion("b")}>
              <img src={bishop} alt="" style={{ width: 50 }} />
            </Box>
            <Box role="presentation" onClick={() => promotion("n")}>
              <img src={knight} alt="" style={{ width: 50 }} />
            </Box>
          </Stack>
        </Box>

        <HStack pb={6}>
          <Button
            size="sm"
            userSelect="none"
            variant="outline"
            colorScheme="yellow"
            onClick={() => {
              props.isFocusMode
                ? props.setIsFocusMode(false)
                : props.onFocusClick(props.board.uuid);
            }}
          >
            <Icon as={props.isFocusMode ? GiExpand : GiSpades} w={4} h={4} />
          </Button>
          <Button
            size="sm"
            isDisabled={turn === 0}
            onClick={() => onResetClick()}
            userSelect="none"
            variant="outline"
            colorScheme="yellow"
          >
            <Icon as={FiRotateCcw} w={4} h={4} />
          </Button>
          <Button
            size="sm"
            isDisabled={turn === 0}
            onClick={() => onBackClick()}
            userSelect="none"
            variant="outline"
            colorScheme="yellow"
          >
            <ArrowLeftIcon w={4} h={4} />
          </Button>
          <Button
            size="sm"
            isDisabled={moves.length < turn + 1}
            onClick={() => onForwardClick()}
            userSelect="none"
            variant="outline"
            colorScheme="yellow"
          >
            <ArrowRightIcon w={4} h={4} />
          </Button>

          <TooltipChakra label={props.board.notes} whiteSpace="pre-wrap">
            <Button
              size="sm"
              userSelect="none"
              variant="outline"
              colorScheme="yellow"
              isDisabled={showNotesCond()}
            >
              <MdNotes w={4} h={4} />
            </Button>
          </TooltipChakra>

          {props.userLoadedRef.current &&
          props.user.uid === window.location.pathname.split("/").pop() ? (
            <Button
              variant="outline"
              colorScheme="yellow"
              size="sm"
              userSelect="none"
              onClick={() => props.onClickEdit(props.board)}
            >
              <Icon as={BsPencilSquare} w={4} h={4} />
            </Button>
          ) : (
            <></>
          )}
        </HStack>
      </VStack>
    </div>
  );
};

export default React.memo(Board);
