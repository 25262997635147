import React, { useState, useRef } from "react";

import { useNavigate } from "react-router";
import Helper from "./functions/Helper";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  VStack,
  Icon,
  Image,
  Text,
  Link,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Center,
} from "@chakra-ui/react";

import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";

import { Link as LinkDom } from "react-router-dom";

// import { Link } from "react-router-dom";

import { MdFavorite } from "react-icons/md";

import { SiDiscord } from "react-icons/si";

import { RiDiscordLine } from "react-icons/ri";

import { BsGrid } from "react-icons/bs";

// import { ImShare } from "react-icons/im";

import {
  AiOutlineHeart,
  AiOutlineLogout,
  AiOutlineLogin,
} from "react-icons/ai";

import { HamburgerIcon } from "@chakra-ui/icons";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { FaFacebook, FaGoogle, FaTwitter } from "react-icons/fa";
import {
  auth,
  addUser,
  googleProvider,
  facebookProvider,
  twitterProvider,
  checkUserExists,
  updateUser,
} from "./base";
// import { signInWithPopup } from "firebase/auth";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const Header = (props) => {
  //////////////////////
  //////// UI Stuff
  ////////////////////

  const forceUpdate = useForceUpdate;
  const toast = useToast();
  const navigate = useNavigate();

  const {
    isOpen: isOpenLogin,
    onOpen: onOpenLogin,
    onClose: onCloseLogin,
  } = useDisclosure();

  const {
    isOpen: isOpenSponsor,
    onOpen: onOpenSponsor,
    onClose: onCloseSponsor,
  } = useDisclosure();

  //Popup Refs

  const sponsorRef = useRef();
  const loginEmailRef = useRef();
  const registerEmailRef = useRef();
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [user, setUser] = useState({});
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    if (user !== null) onCloseLogin();
  });

  const register = async () => {
    // onCloseLogin();
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );

      // checkUserExistsEmail(registerEmail);

      console.log(user);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const login = async () => {
    // onCloseLogin();
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      console.log(user);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const logout = async () => {
    await signOut(auth);
    navigate("/", { replace: true });
    window.location.reload();
    // setTimeout(() => window.location.reload(), 500);
  };

  const signInWith = async (provider) => {
    try {
      const res = await signInWithPopup(auth, provider);
      // console.log(res);

      const user = res.user;

      const isNewUser = await addUser(user);
      // alert(isNewUser);
      if (isNewUser === "new user") {
        // then also add the existing positions to their account.
        // await props.addAllExistingPositionsToUser(user);
        // console.log(user, "new user");
        navigate("/@/" + user.uid, { replace: true });
        window.location.reload();
      } else {
        await updateUser(user);
        props.emptyBoards(user);
        // console.log(user);
        navigate("/@/" + user.uid, { replace: true });
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Error.",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return err;
    }
  };

  // const [show, setShow] = React.useState(false);
  // const [show2, setShow2] = React.useState(false);
  // const handleShowPwClick = () => setShow(!show);
  // const handleShowPwClick2 = () => setShow2(!show2);

  ////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////
  ///////////// Password Less Sign in ////////////////
  ///////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: "https://chesskeep.com",
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: "com.chesskeep.ios",
    // },
    // android: {
    //   packageName: "com.chesskeep.android",
    //   installApp: true,
    //   minimumVersion: "12",
    // },
    // dynamicLinkDomain: "www.chesskeep.com",
  };

  const auth2 = getAuth();
  const sendLink = async () => {
    try {
      // alert(loginEmail);
      onCloseLogin();

      try {
        await sendSignInLinkToEmail(auth2, loginEmail, actionCodeSettings);
        toast({
          title: "Link sent!",
          description:
            "Please check your email for the link to login, it could take up to 5 minutes to receive. You may now close this tab.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      } catch (err) {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 10000,
          isClosable: true,
          position: "top-right",
        });
      }

      // console.log(res);
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.

      // let email = window.localStorage.getItem("emailForSignIn");

      // if (!email) {
      //   // new user

      // }
      window.localStorage.setItem("emailForSignIn", loginEmail);

      // try {
      //   const res = await signInWithPopup(auth, provider);
      //   console.log(res);

      //   const user = res.user;

      //   const isNewUser = await addUser(user);
      //   // alert(isNewUser);
      //   if (isNewUser === "new user") {
      //     // then also add the existing positions to their account.
      //     await props.addAllExistingPositionsToUser(user);
      //     navigate("/@/" + user.uid, { replace: true });
      //     window.location.reload();
      //   } else {
      //     props.emptyBoards(user);
      //     navigate("/@/" + user.uid, { replace: true });
      //     window.location.reload();
      //   }
      // } catch (err) {
      //   console.error(err);
      //   toast({
      //     title: "Error.",
      //     description: err.message,
      //     status: "error",
      //     duration: 5000,
      //     isClosable: true,
      //     position: "top-right",
      //   });
      //   return err;
      // }
      // ...
      // alert("success");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    }
  };

  React.useEffect(() => {
    runEmailLogin();
  }, []);

  const runEmailLogin = async () => {
    if (isSignInWithEmailLink(auth2, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      } else {
        // The client SDK will parse the code from the link for you.
        let v = await signInWithEmailLink(auth2, email, window.location.href);
        // console.log(v, "vv");
        window.localStorage.removeItem("emailForSignIn");

        let exists = await checkUserExists(v.user);
        console.log(v.user);

        // alert(exists);
        if (!exists) {
          await addUser(v.user);
          // await props.addAllExistingPositionsToUser(v.user);
        } else {
          updateUser(v.user);
        }

        navigate("/@/" + v.user.uid, { replace: true });
        window.location.reload();
        // .then((result) => {
        //   // Clear email from storage.
        //   window.localStorage.removeItem("emailForSignIn");
        //   // You can access the new user via result.user
        //   // Additional user info profile not available via:
        //   // result.additionalUserInfo.profile == null
        //   // You can check if the user is new or existing:
        //   // result.additionalUserInfo.isNewUser

        //   const user = result.user;

        // })
        // .catch((error) => {
        //   // Some error occurred, you can inspect the code: error.code
        //   // Common errors could be invalid email and invalid or expired OTPs.
        // });
      }
    }
  };

  const {
    isOpen: isOpenMobileMenu,
    onOpen: onOpenMobileMenu,
    onClose: onCloseMobileMenu,
  } = useDisclosure();

  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();

  const onLoginChange = (e) => {
    setLoginEmail(e.target.value);

    if (e.target.value.includes("gmail.com")) {
      toast({
        title: "Warning.",
        description:
          'Gmail users should use "Sign in with Google" button instead. Reports of Email Link sign-in not working.',
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Box
        id="header"
        pt={{ base: 5, md: 5 }}
        width="100%"
        height={{ base: "70px", md: "70px" }}
        pb={{ base: 5, md: 5 }}
        mb={5}
        style={{ borderBottom: "1px solid #666" }}
      >
        <div
          style={{
            marginLeft: "25px",
            color: "white",
            fontSize: "24px",
            userSelect: "none",
          }}
        >
          <Box float="left">
            <LinkDom to="/" onClick={forceUpdate}>
              <>
                Chess
                <span style={{ fontWeight: "1000", color: "#ecc94b" }}>
                  Keep
                </span>
              </>
            </LinkDom>
          </Box>

          <Box float="right">
            {window.innerWidth < Helper.breakpoints.md ? (
              <>
                <HamburgerIcon
                  w={8}
                  h={8}
                  mr={5}
                  color="white"
                  onClick={onOpenMobileMenu}
                />
                <Drawer
                  isOpen={isOpenMobileMenu}
                  placement="right"
                  onClose={onCloseMobileMenu}
                  size="xl"
                  // finalFocusRef={btnRef}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader color="white" fontWeight="300">
                      {" "}
                      Chess
                      <span style={{ fontWeight: "1000", color: "#ecc94b" }}>
                        Keep
                      </span>
                    </DrawerHeader>

                    <DrawerBody>
                      <Center>
                        <Text fontSize="25px" lineHeight="50px" color="#ccc">
                          {props.userLoaded && user !== null ? (
                            <>
                              <Icon
                                top="2px"
                                position="relative"
                                as={BsGrid}
                                w={5}
                                h={5}
                                color="#ecc94b"
                              />{" "}
                              <LinkDom
                                onClick={forceUpdate}
                                to={`/@/${user.uid}`}
                              >
                                Positions <br />
                              </LinkDom>
                            </>
                          ) : (
                            <>
                              <Icon
                                top="2px"
                                position="relative"
                                as={AiOutlineLogin}
                                w={5}
                                h={5}
                                color="#ecc94b"
                              />{" "}
                              <span
                                onClick={() => {
                                  onOpenLogin();
                                  onCloseMobileMenu();
                                }}
                              >
                                Sign in <br />
                              </span>
                            </>
                          )}
                          <Icon
                            top="2px"
                            position="relative"
                            as={RiDiscordLine}
                            w={5}
                            h={5}
                            color="#ecc94b"
                          />{" "}
                          Discord <br />
                          <Icon
                            top="2px"
                            position="relative"
                            as={AiOutlineHeart}
                            w={5}
                            h={5}
                            color="#ecc94b"
                          />{" "}
                          <span
                            onClick={() => {
                              onOpenSponsor();
                              onCloseMobileMenu();
                            }}
                          >
                            Sponsor
                          </span>{" "}
                          <br />
                          {props.userLoaded ? (
                            <>
                              <Icon
                                top="2px"
                                position="relative"
                                as={AiOutlineLogout}
                                w={5}
                                h={5}
                                color="#ecc94b"
                              />{" "}
                              <span onClick={logout}>Logout</span>
                            </>
                          ) : (
                            <></>
                          )}
                        </Text>
                      </Center>
                    </DrawerBody>

                    <DrawerFooter>
                      <Button
                        variant="outline"
                        mr={3}
                        onClick={onCloseMobileMenu}
                        color="white"
                      >
                        Close
                      </Button>
                      {/* <Button colorScheme="blue">Save</Button> */}
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
              </>
            ) : props.userLoaded ? (
              <>
                {/* <Box float="left" pt={1} mr={6}>
                  <Icon
                    as={ImShare}
                    w={5}
                    h={5}
                    cursor="pointer"
                    onClick={onOpenShare}
                  />
                </Box> */}

                <Box float="left" pt={1} mr={6}>
                  <Link isExternal href="https://discord.gg/yEuVxfTWsR">
                    <Icon as={SiDiscord} w={5} h={5} cursor="pointer" />
                  </Link>
                </Box>

                <Box float="left" fontSize="20" mr={2}>
                  <p style={{ marginTop: "2px", marginRight: "15px" }}>
                    {user && user.uid ? (
                      <LinkDom onClick={forceUpdate} to={`/@/${user.uid}`}>
                        <Image
                          src={
                            user.photoURL && user.photoURL.length > 0
                              ? user.photoURL
                              : "https://www.gravatar.com/avatar/?d=mysteryperson"
                          }
                          w={8}
                          h={8}
                          borderRadius="100px"
                        />
                      </LinkDom>
                    ) : (
                      ""
                    )}
                  </p>
                </Box>

                <Button
                  float="left"
                  colorScheme="yellow"
                  variant="outline"
                  mr={4}
                  onClick={logout}
                >
                  Logout
                </Button>

                <Button
                  mr={6}
                  leftIcon={<Icon color="red.400" as={MdFavorite} />}
                  variant="solid"
                  onClick={onOpenSponsor}
                >
                  <Text color="black" fontSize="15">
                    Sponsor
                  </Text>
                </Button>
              </>
            ) : (
              <>
                <Button
                  float="left"
                  colorScheme="yellow"
                  variant="solid"
                  mr={5}
                  onClick={onOpenLogin}
                >
                  Sign in
                </Button>
                {/* <Button
                  float="left"
                  colorScheme="yellow"
                  mr={6}
                  variant="outline"
                  onClick={test}
                >
                  Test
                </Button> */}
              </>
            )}
          </Box>
        </div>
      </Box>

      <Modal
        initialFocusRef={sponsorRef}
        isOpen={isOpenSponsor}
        onClose={onCloseSponsor}
      >
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>
            <Text ref={sponsorRef}>Why Sponsor?</Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            Hello! 👀 My name is Leon Fresh. I'm a designer, game developer
            (unity3D), web developer and chess enthusiast of 2200 Lichess
            rating.
            <br />
            <br />
            This is my passion project, I am currently unemployed and working on
            this full time for the past 2 weeks. It has taken me a lot of work
            to make it as good as I can envision it to be.
            <br />
            <br />I would greatly appreciate your support of this website's
            continued development and hosting. 🙏 If things don't work out, I
            might end up switching to a freemium subscription model, but ideally
            I would like it to stay free (like Lichess).
            <br />
            <br />
            Given that I get the support of the Chess community, more great
            things are certainly on the horizon. ✨ Some of the features I would
            want to add in the near future are minigames based on your saved
            positions, more social features, and a real homepage.
            <br />
            <br />
            Among the benefits if you donate is that I will develop extra
            features for paying members, such as Bulk PGN importing.
            <br />
            <br />
            You can join us on Discord if you would like to suggest something or
            make a feature request. The link is to the left of your profile
            icon.
            <br />
            <br />
            Until then, stay fresh! 💯
            <br />
            Leon Fresh
            <br />
            <br />
            Post Date 11 Dec 2021
            <br />
            <a href="https://leonfresh.com" target="_blank">
              leonfresh.com
            </a>
          </ModalBody>

          <ModalFooter>
            <a target="_blank" href="https://opencollective.com/chesskeep">
              <Button
                leftIcon={<Icon color="red.400" as={MdFavorite} />}
                colorScheme="yellow"
                mr={3}
              >
                Sponsor
              </Button>
            </a>
            <Button
              colorScheme="yellow"
              variant="outline"
              onClick={onCloseSponsor}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* 
      <Modal
        initialFocusRef={registerEmailRef}
        isOpen={isOpenRegister}
        onClose={onCloseRegister}
      >
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>Sign up</ModalHeader>
          <ModalCloseButton />

        </ModalContent>
      </Modal> */}

      <Modal
        initialFocusRef={loginEmailRef}
        isOpen={isOpenLogin}
        onClose={onCloseLogin}
      >
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>Sign in</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <HStack spacing={5}>
                  <Input
                    ref={loginEmailRef}
                    onChange={(e) => onLoginChange(e)}
                    value={loginEmail}
                    placeholder="Email"
                    mb={4}
                  />

                  <Button
                    top="-8px"
                    position="relative"
                    colorScheme="yellow"
                    onClick={() => sendLink()}
                  >
                    Sign in
                  </Button>
                </HStack>
              </FormControl>
              <br />

              <Button
                ref={loginEmailRef}
                leftIcon={<FaGoogle />}
                marginBottom="20px"
                mt={2}
                colorScheme="red"
                onClick={() => {
                  signInWith(googleProvider);
                }}
              >
                Sign in with Google
              </Button>

              <br />
              <Button
                mt={5}
                ref={loginEmailRef}
                leftIcon={<FaFacebook />}
                colorScheme="blue"
                onClick={() => {
                  signInWith(facebookProvider);
                }}
              >
                Sign in with Facebook
              </Button>
              <br />
              {/* <Button
                mt={5}
                ref={loginEmailRef}
                leftIcon={<FaTwitter />}
                colorScheme="blue"
                backgroundColor="#1da1f2"
                onClick={() => {
                  signInWith(twitterProvider);
                }}
              >
                Sign in with Twitter
              </Button>
              <br /> */}
            </VStack>
          </ModalBody>
          <ModalFooter></ModalFooter>

          {/* <Tabs>
            <TabList>
              <Tab>Sign in</Tab>
              <Tab>Sign up</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <ModalBody mb={7}>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      ref={loginEmailRef}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      placeholder="Email"
                      mb={4}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={show2 ? "text" : "password"}
                        onChange={(e) => setLoginPassword(e.target.value)}
                        placeholder="Enter Password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          colorScheme="yellow"
                          onClick={handleShowPwClick2}
                        >
                          {show2 ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="yellow" onClick={login}>
                    Sign in
                  </Button>
                </ModalFooter>
              </TabPanel>
              <TabPanel>
                <ModalBody mb={7}>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      ref={registerEmailRef}
                      onChange={(e) => setRegisterEmail(e.target.value)}
                      placeholder="Email"
                      mb={4}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={show ? "text" : "password"}
                        onChange={(e) => setRegisterPassword(e.target.value)}
                        placeholder="Enter Password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          colorScheme="yellow"
                          onClick={handleShowPwClick}
                        >
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button width="100%" colorScheme="yellow" onClick={register}>
                    Sign up
                  </Button>
                </ModalFooter>

                <DividerWithText my={6}>OR</DividerWithText>
                <Center>
                  <Button
                    leftIcon={<FaGoogle />}
                    mr={3}
                    variant="outline"
                    colorScheme="blue"
                    onClick={googleSigninFunc}
                  >
                    Sign in with Google
                  </Button>
                </Center>
              </TabPanel>
            </TabPanels>
          </Tabs> */}
        </ModalContent>
      </Modal>

      {/* <Modal
        // initialFocusRef={loginEmailRef}
        isOpen={isOpenShare}
        onClose={onCloseShare}
      >
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>Share</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default Header;
